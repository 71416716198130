import React, { Component } from 'react'
import styled, { keyframes } from 'styled-components'
import CarouselChervonTop from '../icons/CarouselChevronTop'
import throttle from 'lodash/throttle'

const MINIMUM_HEIGHT_TO_DISPLAY_SCROLL_ICON = 100

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`

const StyledTop = styled.div`
  display: ${props => (props.displayScrollTop ? 'block' : 'none')};
  position: fixed;
  right: 0.5rem;
  bottom: 1.5rem;
  z-index: 1;
  cursor: pointer;
  > svg {
    width: 3.75rem;
    height: 3.75rem;
    color: #1557bf;
  }
  animation: ${fadeIn} 0.35s ease-in;
`

class ScrollToTop extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.handleScrollTopDisplay = throttle(
      this.handleScrollTopDisplay.bind(this),
      200
    )
    this.handleScrollToTop = this.handleScrollToTop.bind(this)
  }

  handleScrollTopDisplay() {
    const scrollTop =
      window.pageYOffset ||
      (document.documentElement || document.body.parentNode || document.body)
        .scrollTop

    if (
      scrollTop > MINIMUM_HEIGHT_TO_DISPLAY_SCROLL_ICON !==
      this.state.displayScrollTop
    ) {
      this.setState({
        displayScrollTop: scrollTop > MINIMUM_HEIGHT_TO_DISPLAY_SCROLL_ICON,
      })
    }
  }

  handleScrollToTop() {
    import('react-scroll').then(scroll => {
      scroll.animateScroll.scrollToTop({
        duration: 500,
        smooth: true,
        ignoreCancelEvents: false,
      })
    })
  }

  componentDidMount() {
    this.handleScrollTopDisplay()
    window.addEventListener('scroll', this.handleScrollTopDisplay, false)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScrollTopDisplay)
  }

  render() {
    return (
      <StyledTop
        id="scroll-top"
        onClick={this.handleScrollToTop}
        displayScrollTop={this.state.displayScrollTop}
      >
        <CarouselChervonTop />
      </StyledTop>
    )
  }
}

export default ScrollToTop
