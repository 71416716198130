import React, { Component } from 'react'
import { SCREEN_SIZE } from '../../lib/Media'
import dynamic from 'next/dynamic'
import Header from '../../containers/Header'
import Main from '../../containers/Main/Main'
import styled from 'styled-components'
import { withRouter } from 'next/router'
import ScrollToTop from '../../components/ScrollToTop'
import GlobalContext from '../../components/GlobalContext'
import { disablePageScroll } from '../../lib/utils'
const DeliverySlotHelloBar = dynamic(() =>
  import('../../components/DeliverySlotHelloBar/DeliverySlotHelloBar')
)
const Footer = dynamic(() => import('../../containers/Footer'))

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`
const FooterWrapper = styled.footer`
  flex-shrink: 0;

  /*Removed the footer in mobiles*/
  ${SCREEN_SIZE.Only.Mobile} {
    display: none;
  }
  ${SCREEN_SIZE.Only.MobileL} {
    display: none;
  }
`
const MainWrapper = styled.div`
  margin: 0.8125rem 0 0;

  ${SCREEN_SIZE.From.Tablet} {
    margin-top: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    min-height: calc(100vh - 6.25rem - 28.5625rem);
  }

  ${SCREEN_SIZE.From.Desktop} {
    min-height: calc(100vh - 12.625rem - 19.0781rem);
  }

  ${SCREEN_SIZE.From.UhdL} {
    max-width: 100rem;
    margin-left: auto;
    margin-right: auto;
  }

  /* To override all padding/margin whenever we set no gap */
  ${({ noWrapper }) =>
    noWrapper === 'noGap' &&
    'padding: 0 !important; margin: 0 auto !important;'}
`

const Overlay = styled.div`
  ${SCREEN_SIZE.From.Tablet} {
    position: fixed;
    top: ${({ hasBackdrop }) =>
      hasBackdrop
        ? (window &&
            window.getComputedStyle(document.getElementsByTagName('header')[0])
              .height) ||
          '162px'
        : ''};
    background-color: rgba(51, 51, 51, 0.8);
    z-index: 100;
    width: 100vw;
    height: 100vh;
    transition: opacity 0.2s 0.2s, visibility 0.2s 0.2s;
    opacity: ${({ hasBackdrop }) => (hasBackdrop ? '1' : '0')};
    visibility: ${({ hasBackdrop }) => (hasBackdrop ? 'visible' : 'hidden')};
  }
`

class Layout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      backdrop: [],
    }
    this.handleBackdrop = this.handleBackdrop.bind(this)
  }

  handleBackdrop(value, component = 'default') {
    //possibly having many backdrops at the same time
    if (value) {
      if (!this.state.backdrop.some(x => x === component)) {
        this.setState({
          backdrop: [...this.state.backdrop, component],
        })
      }
      return
    }

    this.setState(prevState => ({
      backdrop: prevState.backdrop.filter(x => x !== component),
    }))
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.backdrop?.length !== this.state.backdrop.length) {
      const shouldEnableScroll = !this.state.backdrop.length
      if (shouldEnableScroll) {
        disablePageScroll(false)
      }
    }
  }

  render() {
    const {
      className,
      children,
      organizationData,
      noWrapper,
      noFooter,
      categoryList,
      router,
    } = this.props

    const { backdrop } = this.state

    return (
      <React.Fragment>
        <Container className={className} data-testid="layout">
          <div>
            <Header
              organizationData={organizationData}
              categoryList={categoryList}
              onHandleBackdrop={this.handleBackdrop}
              hasNewVouchers={this.props.hasNewVouchers}
              hasPaymentAlert={this.props.hasPaymentAlert}
            />
            {router?.pathname === '/' && <DeliverySlotHelloBar />}
            <Overlay hasBackdrop={backdrop && backdrop.length > 0} />
            <MainWrapper noWrapper={noWrapper}>
              <Main noWrapper={noWrapper}>{children}</Main>
            </MainWrapper>
          </div>
          <FooterWrapper>
            {noFooter ? '' : <Footer organizationData={organizationData} />}
          </FooterWrapper>
          <ScrollToTop />
        </Container>
      </React.Fragment>
    )
  }
}

Layout.defaultProps = {
  organizationData: {},
}

const LayoutWrapper = props => (
  <GlobalContext.Consumer>
    {({ experiments, remoteConfig }) => (
      <Layout
        {...props}
        experiments={experiments}
        remoteConfig={remoteConfig}
      />
    )}
  </GlobalContext.Consumer>
)

export default withRouter(LayoutWrapper)
