function productionCleanup(layouts) {
  layouts.map(layout => {
    if (
      layout?.name === 'ProductCollection' &&
      layout?.value?.collection?.product
    ) {
      const products = layout?.value?.collection?.product
      layout.value.collection.product = products.map(product => {
        delete product.tags
        delete product.categoryDataMapping
        delete product.secondaryCategoryIds
        delete product.barcodes

        product.brand = {
          name: product.brand.name,
        }

        product.storeSpecificData = product.storeSpecificData.map(
          storeSpecific => {
            delete storeSpecific.store
            return storeSpecific
          }
        )
        product.primaryCategory = parseCategoryTree(product.primaryCategory)
        return product
      })
      return layout
    } else if (layout?.name === 'ImageSlideShow') {
      // Delete the images inside data as it's already exists in values too
      delete layout.data.images
      return layout
    } else {
      return layout
    }
  })
  return layouts
}

function parseCategoryTree(primaryCategory) {
  if (!primaryCategory) {
    return null
  }

  const parent = parseCategoryTree(primaryCategory.parentCategory)

  const newCatStructure = {
    name: primaryCategory.name,
    parentCategory: parent,
  }
  return newCatStructure
}

export { productionCleanup }
