import { getAllConfig } from './../utils/configService'

const getActiveExperimentVariants = () => {
  const allExp = getAllConfig()
  const activeExpVariants = []
  Object.keys(allExp).forEach(key => {
    const expConfig = allExp[key].config ? JSON.parse(allExp[key].config) : {}
    if (expConfig?.variantKey) {
      activeExpVariants.push(expConfig?.variantKey)
    }
  })
  return activeExpVariants.join(',')
}

export { getActiveExperimentVariants }
