import { getConfigFor } from '../utils/configService'
import { SPLIT_FEATURES } from '../utils/constants'
import { generateQueryString } from '../utils/Utils'
import { get, headers } from '../lib/fetch'

function getPurchaseOrderAmendmentHomeSplit() {
  const {
    isEnabled: isPurchaseOrderAmendmentHomeEnabled,
    configuration: config,
    raw,
  } = getConfigFor(SPLIT_FEATURES.PURCHASE_ORDER_AMENDMENT_HOME_ENABLED)
  return {
    isEnabled:
      isPurchaseOrderAmendmentHomeEnabled || raw?.treatment === 'on_new',
    excludedStoreIds: config?.excludeFromStore || [],
    treatment: raw?.treatment,
  }
}

function getPurchaseOrderAmendmentSplit() {
  const { isEnabled: isPurchaseOrderAmendmentEnabled } = getConfigFor(
    SPLIT_FEATURES.PURCHASE_ORDER_AMENDMENT_ENABLED
  )
  return {
    isEnabled: isPurchaseOrderAmendmentEnabled,
  }
}

async function fetchOrders({
  isLoggedIn,
  checkoutAddress,
  onSuccess,
  onError,
}) {
  const purchaseOrderAmendmentHomeSplit = getPurchaseOrderAmendmentHomeSplit()
  const purchaseOrderAmendmentSplit = getPurchaseOrderAmendmentSplit()
  const storeId = checkoutAddress?.storeId
  if (
    isLoggedIn &&
    storeId &&
    purchaseOrderAmendmentHomeSplit.isEnabled &&
    purchaseOrderAmendmentSplit.isEnabled &&
    purchaseOrderAmendmentHomeSplit.excludedStoreIds.indexOf(storeId) === -1
  ) {
    const queryString = generateQueryString({
      orderType: 'DELIVERY,OFFLINE',
      postalCode: checkoutAddress?.pincode,
      amendment: true,
      storeId,
    })
    try {
      const resp = await get(`v2/orders${queryString}`, {
        headers: headers(),
      })
      const orders = resp.orders
      if (!orders?.length) {
        onSuccess({ nextDeliveryOrder: null })
      } else {
        // get the next delivery order
        const filteredOrders = orders.filter(order => {
          const startTime = `${order.slot.date}T${order.slot.start_time}`
          return new Date(startTime) > new Date()
        })
        filteredOrders.sort(
          (a, b) =>
            new Date(`${a.slot.date}T${a.slot.start_time}`) -
            new Date(`${b.slot.date}T${b.slot.start_time}`)
        )
        onSuccess({ nextDeliveryOrder: filteredOrders[0] || null })
      }
    } catch {
      onError({ nextDeliveryOrder: null })
    }
  } else {
    onSuccess({ nextDeliveryOrder: null })
  }
}

export {
  getPurchaseOrderAmendmentHomeSplit,
  getPurchaseOrderAmendmentSplit,
  fetchOrders,
}
