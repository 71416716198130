const getLayoutUrl = (
  slug,
  checkoutAddress,
  shouldCheckPFC,
  defaultStoreId
) => {
  if (checkoutAddress && checkoutAddress.storeId) {
    const { storeId } = checkoutAddress
    if (shouldCheckPFC) {
      slug = slug + `&storeId=${defaultStoreId}`
    } else {
      if (slug.indexOf('?url=') > -1) {
        slug = slug + `&storeId=${storeId}`
      } else {
        slug = slug + `?storeId=${storeId}`
      }
    }
    return slug
  }

  return slug
}

export default getLayoutUrl
