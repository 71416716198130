import nookies from 'nookies'

export function cookieValuetoJSON(ctx, cookieKey) {
  let result = nookies.get(ctx)[cookieKey]
  if (typeof result === 'string') {
    try {
      result = JSON.parse(result)
    } catch {
      return {}
    }
  }
  return result
}
