import React from 'react'
import styled from 'styled-components'
import { SCREEN_SIZE } from './../../lib/Media'

const MainContainer = styled.main`
  margin: 0 auto;
  min-height: 20vh;

  padding: 0 1rem;
  ${SCREEN_SIZE.From.Tablet} {
    padding: 0 0 2rem 0;
  }

  ${SCREEN_SIZE.From.Desktop} {
    padding-left: 0;
    padding-right: 0;
  }
`

const MainTablet = styled(MainContainer)`
  ${SCREEN_SIZE.From.Tablet} {
    padding: 0.5rem 0;
    margin: 0rem;
  }
`
const MainDesktop = styled(MainContainer)`
  ${SCREEN_SIZE.From.Desktop} {
    padding: 0.5rem 0;
    margin: 0rem;
  }
`

const MainHd = styled(MainContainer)`
  ${SCREEN_SIZE.From.Hd} {
    padding: 0.5rem 0;
    margin: 0rem;
  }
`

const NoGapContainer = styled(MainContainer)`
  padding: 0;
  margin: 0;
`

const Main = ({ children, noWrapper = 'default' }) => (
  <React.Fragment>
    {noWrapper === 'fromMobile' && <main>{children}</main>}
    {noWrapper === 'fromTablet' && <MainTablet>{children}</MainTablet>}
    {noWrapper === 'fromDesktop' && <MainDesktop>{children}</MainDesktop>}
    {noWrapper === 'fromHd' && <MainHd>{children}</MainHd>}
    {noWrapper === 'noGap' && <NoGapContainer>{children}</NoGapContainer>}
    {noWrapper === 'default' && <MainContainer>{children}</MainContainer>}
  </React.Fragment>
)

export default Main
