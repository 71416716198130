const getIosSmartBannerContent = (context = {}) => {
  const { req } = context
  let pageUrl = ''
  if (typeof window === 'undefined') {
    pageUrl = `${req.protocol}://${req.get('host')}${req.originalUrl}`
  } else {
    pageUrl = window.location.href
  }
  return `app-id=1012365118, app-argument=${pageUrl}`
}

export { getIosSmartBannerContent }
