import React, { useEffect } from 'react'
import styled from 'styled-components'
import SvgDeliveryVan from '@ntuctech/devex-tangram/Icon/SvgDeliveryVan'
import SvgClose from '@ntuctech/devex-tangram/Icon/SvgClose'
import SvgArrowRight from '@ntuctech/devex-tangram/Icon/SvgArrowRight'
import Link from 'next/link'
import { SCREEN_SIZE } from '../../lib/Media'
import isToday from 'date-fns/is_today'
import isTomorrow from 'date-fns/is_tomorrow'
import format from 'date-fns/format'
import { AMP_EVENT_NAME, getPageTypeByUrl } from '../../lib/amplitude'
import { GTM_ENV_VAR as GEV } from '../../lib/eventTrackerFunction'
import { generateQueryString } from '../../utils/Utils'
import { getPurchaseOrderAmendmentHomeSplit } from '../../utils/amendOrderNotificationUtils'

const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: #2070ef;
  border-radius: 0.25rem;
  color: white;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1rem;
  justify-content: center;
  padding: 0.25rem 0.625rem;
  margin-bottom: 1rem;

  ${SCREEN_SIZE.Below.Tablet} {
    justify-content: space-between;
    padding: 0.75rem 0.625rem;
  }
`

const LeftContainer = styled.div`
  display: flex;

  ${SCREEN_SIZE.Below.Tablet} {
    align-items: center;
  }
`

const RightContainer = styled.div`
  display: none;

  ${SCREEN_SIZE.Below.Tablet} {
    display: block;
    padding-left: 1.75rem;
  }
`

const OldRightContainer = styled.div``

const Title = styled.div`
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 0 0.5rem;

  ${SCREEN_SIZE.Below.Tablet} {
    margin: 0;
  }
`

const CloseButton = styled.button`
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  margin: 0;
  outline: none;
  border: none;
  display: flex;
`

const Icon = styled.div`
  height: 1.5rem;
  width: 1.5rem;
  ${SCREEN_SIZE.Below.Tablet} {
    margin-right: 0.625rem;
  }
`

const SmallIcon = styled.div`
  height: 1.25rem;
  width: 1.25rem;
  margin-left: 0.25rem;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${SCREEN_SIZE.Below.Tablet} {
    flex-direction: column;
    align-items: flex-start;
  }
`

const Description = styled.div`
  margin-right: 1rem;

  ${SCREEN_SIZE.Below.Tablet} {
    margin: 0;
  }
`

const ViewOrderLabel = styled.div`
  display: inline-block;

  ${SCREEN_SIZE.Below.Tablet} {
    display: none;
  }
`

const ViewOrderLink = styled.a`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-decoration: none;
  color: white;
`

const formatCutoffTime = latestTimeToAmend => {
  const dayIsToday = isToday(latestTimeToAmend)
  const dayIsTomorrow = isTomorrow(latestTimeToAmend)
  const day = dayIsToday
    ? 'today'
    : dayIsTomorrow
    ? 'tomorrow'
    : format(latestTimeToAmend, 'dddd')

  const formattedDate = format(latestTimeToAmend, 'D MMM')

  return dayIsToday || dayIsTomorrow
    ? `${format(latestTimeToAmend, 'ha')} ${day}`
    : `${day}, ${formattedDate}, ${format(latestTimeToAmend, 'ha')}`
}

const trackOrderClick = track => {
  track({
    [GEV.AMPLITUDE_EVENT_NAME]: AMP_EVENT_NAME.VIEW_ORDER_CLICKED,
    [GEV.AMPLITUDE_CTA_LOCATION]: {
      pageName: getPageTypeByUrl(),
    },
  })
}

const formatSlot = slot => {
  const startTime = new Date(`${slot.date}T${slot.start_time}`)
  const endTime = new Date(`${slot.date}T${slot.end_time}`)

  const day = isToday(startTime)
    ? 'Today'
    : isTomorrow(startTime)
    ? 'Tomorrow'
    : format(startTime, 'dddd')
  return `${day}, ${format(startTime, 'D MMM')}, ${format(
    startTime,
    'h:mma'
  )}-${format(endTime, 'h:mma')}`
}

const AmendOrderNotificationBannerInner = ({ order, track, onClose }) => {
  const { amendment } = order
  const cutoffTime = formatCutoffTime(amendment.latestTimeToAmend)

  const destinationQueryString = generateQueryString({
    personalisationApi: 'recommended-for-you',
    personalisationType: 'RECOMMENDED_FOR_YOU',
    sorting: 'POPULARITY',
    title: 'Recommended for you',
    ctaSublocation: 'Recommended for you',
    pageType: 'recommended-for-you',
    amendOrder: true,
  })

  return (
    <Link
      href={`/product-listing${destinationQueryString}`}
      style={{ textDecoration: 'none' }}
      onClick={() => trackOrderClick(track)}
    >
      <Container>
        <LeftContainer>
          <Icon>
            <SvgDeliveryVan color="#fff" />
          </Icon>
          <ContentWrapper>
            <Title>
              You can add items to your upcoming delivery by {cutoffTime}
            </Title>
          </ContentWrapper>
        </LeftContainer>
        <RightContainer>
          <CloseButton onClick={onClose}>
            <SmallIcon>
              <SvgClose color="#fff" />
            </SmallIcon>
          </CloseButton>
        </RightContainer>
      </Container>
    </Link>
  )
}

const OldAmendOrderNotificationBannerInner = ({ order, track }) => {
  const { slot, reference_number: referenceNumber } = order
  const formattedSlot = formatSlot(slot)

  return (
    <Container>
      <LeftContainer>
        <Icon>
          <SvgDeliveryVan color="#fff" />
        </Icon>
        <ContentWrapper>
          <Title>Forgot something? You can still add items</Title>
          <Description>{`Upcoming delivery ${formattedSlot}`}</Description>
        </ContentWrapper>
      </LeftContainer>
      <OldRightContainer>
        <Link href={`/orders/${referenceNumber}`} passHref legacyBehavior>
          <ViewOrderLink onClick={() => trackOrderClick(track)}>
            <ViewOrderLabel>View order</ViewOrderLabel>
            <SmallIcon>
              <SvgArrowRight color="#fff" />
            </SmallIcon>
          </ViewOrderLink>
        </Link>
      </OldRightContainer>
    </Container>
  )
}

const MobileWrapper = styled.div`
  display: none;

  ${SCREEN_SIZE.Below.Tablet} {
    display: block;
  }
`

const TabletWrapper = styled.div`
  display: block;

  ${SCREEN_SIZE.Below.Tablet} {
    display: none;
  }
`

const StyledLink = styled.a`
  text-decoration: none;
`

const AmendOrderNotificationBanner = ({ order, track, show, onClose }) => {
  // track message shown GA event on banner mount
  useEffect(() => {
    if (!order || !show) {
      return
    }
    track({
      [GEV.SCREEN_NAME]: 'grocery-online | home',
      [GEV.EVENT_CAT]: 'browsing-home_main',
      [GEV.EVENT_ACTION]: 'message show',
      [GEV.EVENT_LABEL]: 'POA home banner',
      [GEV.EVENT]: 'hm_push_event',
    })
  }, [order, track, show])

  if (!order || !show) {
    return null
  }

  const { treatment } = getPurchaseOrderAmendmentHomeSplit()
  if (treatment === 'on_new') {
    return (
      <div>
        <AmendOrderNotificationBannerInner
          order={order}
          track={track}
          onClose={onClose}
        />
      </div>
    )
  }

  return (
    <div>
      <MobileWrapper>
        <Link
          href={`/orders/${order.reference_number}`}
          passHref
          legacyBehavior
        >
          <StyledLink onClick={() => trackOrderClick(track)}>
            <OldAmendOrderNotificationBannerInner order={order} track={track} />
          </StyledLink>
        </Link>
      </MobileWrapper>
      <TabletWrapper>
        <OldAmendOrderNotificationBannerInner order={order} track={track} />
      </TabletWrapper>
    </div>
  )
}

export default AmendOrderNotificationBanner
